<template>
  <div class="breadcrumb no-print">
    <ul>
      <li
        v-for="(breadcrumb, idx) in breadcrumbList"
        :key="idx"
        @click="routeTo(idx)"
        :class="{'linked': !!breadcrumb.link}">

        {{ breadcrumb.name }}

      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'Breadcrumb',
  data () {
    return {
      breadcrumbList: []
    }
  },
  mounted () { this.updateList() },
  watch: { '$route' () { this.updateList() } },
  methods: {
    routeTo (pRouteTo) {
      if (this.breadcrumbList[pRouteTo].link) this.$router.push({ name: this.breadcrumbList[pRouteTo].link })
    },
    updateList () { this.breadcrumbList = this.$route.meta.breadcrumb }
  }
}
</script>

<style scoped lang="scss">
  .breadcrumb {
    padding: 5px 28px;
    background-color: #f5f2f2;

    ul {
      list-style: none;
      display: flex;
      padding-left: 0;

      li {
        font-size: .9em;
        opacity: .7;
      }
    }

    ul > li:not(:last-child)::after {
      content: '/';
      float: right;
      font-size: 1em;
      margin: 0 .5em;
      cursor: default;
    }

    .linked {
      cursor: pointer;
      font-weight: normal;
      opacity: 1;
    }
  }
</style>
